import { useState } from 'react';
import wineImagePlaceholder from '../../assets/images/wine-image-placeholder.png';
import clsx from 'clsx';

type Props = {
  src?: string | null;
  size?: 'sm' | 'md';
  className?: string;
};

export const ProductImage = ({ src, size = 'md', className }: Props) => {
  const [imageSrc, setImageSrc] = useState(src || wineImagePlaceholder);

  const handleError = () => {
    setImageSrc(wineImagePlaceholder);
  };

  return (
    <div
      className={clsx(
        'rounded-2.5 relative overflow-hidden',
        {
          'min-w-10 w-10 h-10': size === 'sm',
          'min-w-12 w-12 h-12': size === 'md',
        },
        className,
      )}
    >
      <img
        alt="Product image"
        src={imageSrc}
        onError={handleError}
        className="w-full h-full object-cover"
      />
    </div>
  );
};
