import { useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { Sidebar } from './sidebar';
import { useGetAuthUser } from '../../hooks';
import { DISTRIBUTOR, RETAILER } from '../../constants';
import { useLoginUserMutation } from '../../redux/api/authApi/authApi';
import { LoadingScreen } from '../../components';
import type { SidebarProps } from '../../types';
import { CartProvider } from '../cart/Provider';
import {
  adminSidenavConfig,
  distributorSidenavConfig,
  retailerSidenavConfig,
} from './sidebar/constants';
import { SidebarToggler } from './sidebar/SidebarToggler';
import { Header } from './header';
import clsx from 'clsx';

export default function DashboardLayout({
  isSidebarOpen = false,
  setIsSidebarOpen = () => null,
}: SidebarProps) {
  const [logUserData, { isLoading }] = useLoginUserMutation();
  const { user } = useGetAuthUser();
  const headerPortalContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    logUserData();
  }, []);

  const sidenav =
    user?.userType === RETAILER
      ? retailerSidenavConfig
      : user?.userType === DISTRIBUTOR
        ? distributorSidenavConfig
        : adminSidenavConfig;

  return (
    <div className="bg-background">
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <Sidebar
            sidenav={sidenav}
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />

          <div className="overflow-auto dashboard-layout">
            <div className="w-full min-h-screen bg-background flex flex-col">
              <SidebarToggler isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
              <div
                className={clsx(
                  'flex flex-col lg:px-8 px-4 py-4 lg:ml-[16rem] lg:pb-4 lg:min-h-screen lg:h-screen',
                  {
                    'min-h-screen-minus-sidebar-toggler h-screen-minus-sidebar-toggler':
                      !isSidebarOpen,
                    'min-h-screen h-screen': isSidebarOpen,
                  },
                )}
              >
                <Header portalContainerRef={headerPortalContainerRef} />
                {user?.userType === RETAILER ? (
                  <CartProvider user={user}>
                    <Outlet context={{ headerPortalContainerRef, setPortalContent: () => null }} />
                  </CartProvider>
                ) : (
                  <Outlet context={{ headerPortalContainerRef, setPortalContent: () => null }} />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
