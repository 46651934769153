import { ProductImage } from '../../../../components/shared';
import { DiscountResponse, Product } from '../../../../redux/api/retailerOrderListApi/types';
import { Button, Counter } from '../../../../components';
import { useCart } from '../../../../layout/cart/useCart';
import { TotalPriceWithDiscount } from './components/TotalPriceWithDiscount';
import { BottleDiscountTooltipContent } from '../../../../components/shared/Discounts/BottleDiscountTooltipContent';
import { Tooltip } from 'react-tooltip';
import { useState } from 'react';
import { IconInfo } from '../../../../assets/icons';
import clsx from 'clsx';
import { ProductWithSalesInfo } from '../../../../redux/api/dashboardApi/types';

type BaseProps = {
  initialDiscounts: Record<string, DiscountResponse>;
  isLoading: boolean;
  showSalesCount?: boolean;
};

type PropsWithSalesInfo = BaseProps & {
  showSalesCount: true;
  product: ProductWithSalesInfo;
};

type PropsWithoutSalesInfo = BaseProps & {
  product: Product;
  showSalesCount?: false;
};

type Props = PropsWithSalesInfo | PropsWithoutSalesInfo;
export const ProductRow = ({ product, isLoading, initialDiscounts, showSalesCount }: Props) => {
  const { getProductQuantityInCart, changeProductQuantity } = useCart();
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const productQuantityInCart = getProductQuantityInCart(product);
  const tooltipId = `${showSalesCount ? 'orderedProductsDiscountsTooltip' : 'topSellingDiscountsTooltip'}_${product.productId}`;
  const haveBottleDiscounts = !!initialDiscounts?.[product.productId]?.discountOnUnits?.length;

  return (
    <div className="flex justify-between pt-3 pb-[11px] border-b border-grey-6">
      <div className="flex gap-2 items-start max-w-[70%] flex-1">
        <ProductImage size="sm" src={product.azImageUrl} />
        <div className="max-w-[calc(100%-56px)]">
          <div
            className={clsx('flex gap-2 items-center', {
              'py-2': !showSalesCount && productQuantityInCart,
              'mb-3': !showSalesCount,
              'mb-[3px] max-h-5': showSalesCount,
            })}
          >
            <div className="text-body-md text-grey-1 max-w-full overflow-ellipsis overflow-hidden whitespace-nowrap">
              {product.title}
            </div>
            {haveBottleDiscounts && (
              <div data-tooltip-id={tooltipId}>
                <IconInfo
                  className={clsx('cursor-pointer transition-colors w-4 min-w-4 h-4', {
                    'text-grey-3 hover:text-grey-1': !isTooltipOpen,
                    'text-primary-brand hover:text-primary-brand-dark': isTooltipOpen,
                  })}
                />
              </div>
            )}
          </div>
          <div className="text-grey-3 text-badge-sm">Available: {product.unitsAvailable}</div>
          {showSalesCount && (
            <div className="text-badge-sm flex flex-col pt-3 gap-1">
              <div className="flex gap-2">
                <div className="text-grey-3">Number of bottles bought:</div>
                <div>{product.unitsSold}</div>
              </div>
              <div className="flex gap-2">
                <div className="text-grey-3">Number of orders:</div>
                <div>{product.orderCount}</div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={clsx('flex flex-col justify-between', {
          'py-1': !showSalesCount && productQuantityInCart,
        })}
      >
        <TotalPriceWithDiscount
          initialDiscounts={initialDiscounts}
          product={product}
          isLoading={isLoading}
          productQuantityInCart={productQuantityInCart}
        />
        {productQuantityInCart > 0 ? (
          <Counter
            currentCount={productQuantityInCart}
            onCountChange={(quantity) => changeProductQuantity(product, quantity)}
            className="justify-end"
          />
        ) : (
          <Button
            onClick={() => changeProductQuantity(product, 1)}
            size="small"
            className="ml-auto"
          >
            Buy
          </Button>
        )}
      </div>

      {haveBottleDiscounts && (
        <Tooltip
          setIsOpen={setTooltipOpen}
          isOpen={isTooltipOpen}
          delayHide={0}
          delayShow={0}
          opacity={1}
          className="!bg-white shadow-tooltip z-50 !rounded-2.5 !p-0"
          openOnClick
          clickable
          id={tooltipId}
          place="bottom-end"
        >
          {isTooltipOpen && (
            <BottleDiscountTooltipContent
              onClose={() => setTooltipOpen(false)}
              productsDiscounts={initialDiscounts}
              product={product}
            />
          )}
        </Tooltip>
      )}
    </div>
  );
};
